import React ,{ useState, useEffect} from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {user_links, dealer_links, customer_reject_links} from '../permissions/Permissions'

export const Authentication = (props, {children}) => {
	
   const navigate = useNavigate();
	const {pathname} = useLocation();

	const [access_token] = useState(localStorage.getItem('access_token'));
	const [user_type] = useState(localStorage.getItem('user_type'));
	
	useEffect(() => {

		var promise = new Promise( (resolve, reject) => {
						
			//dealer try to access user links
			if(user_type === 'dealer'){
				if(user_links.includes(pathname)){
					reject("rejected");
				}
			}
			if(user_type === 'user'){
				if(dealer_links.includes(pathname)){
					reject("rejected");
				}
			}
			if(user_type === 'customer'){
				if(customer_reject_links.includes(pathname)){
					reject("rejected");
				}
			}
			
			if (access_token && user_type) {
				resolve("success");
			}else {
				reject("rejected");
			}	
		});
			
		promise.then( result => {
			return result;
		})
		.catch(error => {
			return navigate("/login", { replace: true });
		});
		
	})

	return (
    	<>{props.children}</>
  	)
}