
export const Links = {
   HOME:'/',
   LOGOUT:'/logout',
   CONTACT:'/contact',
   ABOUT:'/about',
   HELP:'/help',
   TERMS:'/terms-and-conditions',
   PRIVACY:'/privacy-policy',
   CHANGE_PASSWORD:'/change-password',
   PROFILE:'/profile',
   MYADDRESSES:'/myaddresses',
   MY_WISHLIST:'/my-wishlist',
   ORDERS:'/orders',
   CHECKOUT:'/checkout',
   PRODUCT:'/product',
   PRODUCTP:'/p',
   CATEGORY:'/printed-tshirt',
   AMS_TICKETS:'/amc-packages',
   LOGIN:'/login',
   REGISTER:'/register',
   CREATE_AMS_TICKET:'/buy-amc-package',
   AMC_BID:'/amc-for-bidding',
   MY_PROPOSALS:'/my-proposals',
   CMS_TICKETS:'/cmc-tickets',
   RESET_PASSWORD:'/reset-password',
   UPCOMING_PRODUCTS:'/upcoming-products',
   FREE_CONSULTATION:'/free-consultation',
   WHYCTMRIHUB:'/why-ctmri-hub',
   AERBLICENSING:'/aerb-licensing',
   PARTSREPAIR:'/parts-repair',
   BLOG:'/blog',
   SHIPPINGPOLICY:'/shipping-policy',
   RSSFEED:'/feed',
   SITEMAP:'/sitemap',
   CUSTOMTSHIRT:'/custom-tshirt-design',
   TSHIRT:'/tshirt',
   NEWARRIVALS:'/new-arrivals',
   FEATUREPRODUCT:'/featured-t-shirts',
   POPULARPRODUCT:'/popular-t-shirts',
   MEMEPRINTEDTSHIRT:'/meme-printed-tshirt',
   ALLPRINTEDTSHIRT:'/all-printed-tshirt',
   T:'/t',
   SEARCH:'/search',
   PAGE:'/page',
   KIDSTSSHIRTS:'/kids-tshirts',
   TESTIMONIALS:'/testimonials',
   WOMENTSSHIRTS:'/women-tshirts',
}