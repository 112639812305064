
import React, { FC, useEffect, useMemo, useState } from 'react';

export interface State {
  speclizationterm: string;
}

const initialState = {
  speclizationterm: '',
};

export const ProductFilterContext = React.createContext<State | any>(initialState);

ProductFilterContext.displayName = 'ProductFilterContext';

export const ProductFilterProvider: FC = (props) => {
  //const param = useParams();

  const [filtergroup, setFiltergroup] = useState([]);
  const [sortby ,setSortby] = useState('');
  const [groupfilterlbl, setGroupfilterlbl] = useState([]);
  const [groupfilterlen, setGroupfilterlen] = useState(0);



  

  const updateGroup = (spec_id) => {
    
    setSpecTerm(spec_id)
  }


  const UpdateSort = (sortby) => {
    
    setSortby(sortby)
  }
  
  const value = useMemo(
    () => ({
      filtergroup, 
      setFiltergroup,
      sortby ,
      setSortby,
      groupfilterlbl, 
      setGroupfilterlbl,
      groupfilterlen, 
      setGroupfilterlen
    }),
    [updateGroup,UpdateSort]
  );

  return <ProductFilterContext.Provider value={value} {...props} />;
};

export const useProductFilter = () => {
  const context = React.useContext(ProductFilterContext);
  if (context === undefined) {
    throw new Error(`ProductFilterContext must be used within a ProductFilterContext`);
  }
  return context;
};