import React, { createContext, useContext, useState, useEffect } from "react";
import {API_ENDPOINT} from '../components/config/Common'
const SiteSettingContext = createContext('SettingsContext');

export const SiteSettingProvider = ({children}) => {

   const [sitelogo, setSiteLogo] = useState(null);
   const [sitelogoalt, setSiteLogoAlt] = useState(null);
   const [homemetatitle, setHomemetatitle] = useState('')
   const [homemetadesc, setHomemetadesc] = useState('');
   const [contactemail, setContactemail] = useState('')
   const [contactphoneno, setContactphoneno] = useState('')
   const [contactaddress, setContactAddress] = useState('')
   const [google_analytics, setGoogle_analytics] = useState('')
   const [default_coupon_code, setDefault_coupon_code] = useState(null)


   useEffect(() => {
      site_settings()
   },[])

   const site_settings = async () => {
   
      const response = await fetch(`${API_ENDPOINT}/get_all_settings`)
      const result = await response.json();
      setSiteLogo(result.data.site_logo);
      setHomemetatitle(result.data.homepage_meta_title);
      setHomemetadesc(result.data.homepage_meta_description);
      setContactemail(result.data.contact_email);
      setContactphoneno(result.data.contact_phone_no);
      setContactAddress(result.data.contact_address);
      setGoogle_analytics(result.data.google_analytics);
      setSiteLogoAlt(result?.data?.site_logo_alt);
      setDefault_coupon_code(result?.data?.default_coupon_code)
   }

   return <SiteSettingContext.Provider value={{ sitelogo , homemetatitle , homemetadesc , contactemail ,
    contactphoneno , contactaddress , google_analytics, sitelogoalt,default_coupon_code}} >{children}</SiteSettingContext.Provider>

}

export const useSiteSettings = () => {
   const context = React.useContext(SiteSettingContext);
   if (context === undefined) {
      throw new Error(`useSiteSettings must be used within a SiteSettingContext`);
   }
   return React.useMemo(() => context, [context]);
   //return context;
};