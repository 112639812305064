import React, { createContext, useContext, useState , useEffect } from "react";
import {API_ENDPOINT, toast_anim, toast_anim_error} from '../components/config/Common'
import {useAuth} from '../provider/AuthProvider'
const CartContext = createContext('CartContext');


export const CartProvider = ({children}) => {
   const [randNum,setRandomNum] = useState(localStorage.getItem('rand_num'));
   const [cartItem, setCartItem] = useState(false)
   const [cartTotal, setCartTotal] = useState(0)
   const [cartCount, setCartCount] = useState(0)
   const [itemCartQty, setItemCartQty] = useState(0)
   const [gstTax, setGstTax] = useState(0)
   const [shipping_t, setShipping_t] = useState('');
   const [shipping_tamt, setShipping_tamt] = useState(0);
   const [cart_coupon_code, setCart_coupon_code] = useState('');
   const [coupon_discount, setCoupon_discount] = useState(0);
   const [total_savingdiscount,setTotal_savingdiscount] = useState(0);
   const {isUser , myInfo} = useAuth();
   

    useEffect(() => {
      setRandomNum(randNum);
   },[localStorage.getItem('rand_num')])



   const getCartItems = () => {
      
      fetch(`${API_ENDPOINT}/get_cart_list?code=${randNum}`,{
         method: 'GET',
         headers : {        
            'Content-Type': 'application/json',
            'Accept': 'application/json',
         }
      }).then(function (response) {
         return response.json();
      }).then( (result)=> {
         
         if(result.status === 1){
            setCartItem(result.data)
            setCartTotal(result.cart_total)
            setCartCount(result.data.length)
            setGstTax(result.gst_total)
            setShipping_tamt(result.checkoutsettings?.shipping_amount);
            setShipping_t(result.checkoutsettings?.shipping);
            setCoupon_discount(result?.coupon_discount);
            setCart_coupon_code(result?.coupon_code);
            setTotal_savingdiscount(result?.total_savingdiscount);
            
         }else{
            setCartItem([]);
            setCartTotal(0);
            setCartCount(0);
            setGstTax(0);
         }
      })
   }

   

   const tax_gst = async (amt) => {

      let amount = Number(amt)
   
      const response = await fetch(`${API_ENDPOINT}/get_cart_gst/${amount}`)
      const data = await response.json();
      
      setGstTax(data.data)
      //return data.data;
   }

   const addCartItem = (item,price_type,color,size,side,customtext,sku,extraprice=0,custom_id=0,custom_qty=0) => {
      //const rand_num = localStorage.getItem('rand_num')
     console.log("item Price",item.price);
      //const user_type = localStorage.getItem('user_type');
     console.log('random number',randNum);
      let user_id =0;
         if(isUser){
            if(myInfo?.id && myInfo?.id > 0){
               user_id = myInfo?.id;
            }  
         }

      if(randNum){
         console.log("IN IF");
         fetch(`${API_ENDPOINT}/add_to_cart`,{
            method: 'POST',
            headers : {        
               'Content-Type': 'application/json',
               'Accept': 'application/json',
            },
            body: JSON.stringify({
               product_id:item.p_id,
               amount:(item.sale_price ? item.sale_price :item.price),
               invoice:randNum,
               seller_id:item.user_id,
               price_type:price_type,
               color:color,
               size:size,
               side:side,
               customtext:customtext,
               sku:sku,
               extra_price:extraprice,
               custom_id:custom_id,
               custom_qty:custom_qty,
               user_id:user_id,
            })
         }).then(function (response) {
               return response.json();
         }).then( (result)=> {
            
            if(result.status === 1){
               getCartItems()
               checkItemInCart(item.p_id)
               toast_anim(result.message)
            }
         })
      }else{
         console.log("IN ADD TO CART ELSE");

         const min = 1000000000;
         const max = 9999999999;
         const rand = Math.floor(Math.random() * (max - min + 1)) + min;
         localStorage.setItem('rand_num',rand);

         setRandomNum(rand);
         fetch(`${API_ENDPOINT}/add_to_cart`,{
            method: 'POST',
            headers : {        
               'Content-Type': 'application/json',
               'Accept': 'application/json',
            },
            body: JSON.stringify({
               product_id:item.p_id,
               amount:(item.sale_price ? item.sale_price :item.price),
               invoice:rand,
               seller_id:item.user_id,
               price_type:price_type,
               color:color,
               size:size,
               side:side,
               customtext:customtext,
               sku:sku,
               extra_price:extraprice,
               custom_id:custom_id,
               custom_qty:custom_qty,
               user_id:user_id,
            })
         }).then(function (response) {
               return response.json();
         }).then( (result)=> {
            
            if(result.status === 1){
               getCartItems()
               checkItemInCart(item.p_id)
               toast_anim(result.message)
            }
         })


      }
   }

   const deleteCartItem = (id,sku) => {
      //const randNum = localStorage.getItem('rand_num')
      if(randNum){
         fetch(`${API_ENDPOINT}/delete_cart_item/${id}?num=${randNum}&sku=${sku}`,{
            method: 'DELETE',
            headers : {        
               'Content-Type': 'application/json',
               'Accept': 'application/json',
            }
         }).then(function (response) {
            return response.json();
         }).then( (result)=> {
            
            if(result.status === 1){
               getCartItems()
               checkItemInCart(id)
               toast_anim_error(result.message)
            }
         })
      }
   }

   const updateItemQty = (id,qty) => {
      if(randNum){
         fetch(`${API_ENDPOINT}/update_item_qty`,{
            method: 'POST',
            headers : {        
               'Content-Type': 'application/json',
               'Accept': 'application/json',
            },
            body: JSON.stringify({
               product_id:id,
               quantity:qty,
               invoice:randNum
            })
         }).then(function (response) {
            return response.json();
         }).then( (result)=> {
            
            if(result.status === 1){
               getCartItems()
               checkItemInCart(id)
            }
         })
      }
   }

   const checkItemInCart = (id) => {
		
      if(randNum){
         fetch(`${API_ENDPOINT}/is_product_in_cart/${id}?invoice=${randNum}`,{
            method: 'GET',
            headers : {
               'Content-Type': 'application/json',
               'Accept': 'application/json',
            }
         }).then(function (response) {
               return response.json();
         }).then( (result)=> {
            
            if(result.status === 1){
               setItemCartQty(result.data.quantity)
            }else{
               setItemCartQty(0)
            }
         })
      }
   }

   
   return <CartContext.Provider value={{ getCartItems, cartItem, setCartItem, cartTotal, cartCount, addCartItem, deleteCartItem, updateItemQty, checkItemInCart, itemCartQty, gstTax , setShipping_t ,setShipping_tamt , shipping_t , shipping_tamt , 
   cart_coupon_code, setCart_coupon_code , coupon_discount, setCoupon_discount , total_savingdiscount , setTotal_savingdiscount }} >{children}</CartContext.Provider>
}

export const useCart = () => {
   const context = React.useContext(CartContext);
   if (context === undefined) {
      throw new Error(`useCart must be used within a CartProvider`);
   }
   return React.useMemo(() => context, [context]);
};